.online-learning-bg {
    height: 530px;
    background-image: linear-gradient(125.69deg, rgba(41, 41, 41, 0.74) 0%, rgba(25, 25, 25, 0.74) 100%), url('../../../assets/blogs/online-learning.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(12px);
    margin: -20px !important;
    position: relative;
}


.blog-hero-text-bg {
    width: 100%;

}



.blog-header-image {

    width: 100% ;
    height: 90%;
    border-radius: 5px;
}


.share-via {
    color: #fff;
    font-family: 'Montserrat', sans-serif,;
    opacity: 0.9;
    margin-bottom: -.9rem;
    font-weight: 300;
}



@media only screen and (max-width: 520px) {
    .online-learning-bg {
        height: 450px;
        filter: blur(5px);
        margin: -0px !important;

    }

    .break-none {
        display: none;
    }

   
}