.blog-hero-bg {
    margin-top: 60px;
    height: 512px;
    width: 100%;

    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;


    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-hero-bg-max-width {
    max-width: 1440px;
    padding: 64px;
}

.blog-hero-bg-title {
    color: #FFF;
    font-family: Archivo;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 1rem;
}

.blog-hero-bg-description {
    margin-top: 1rem;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    opacity: .9;
}


@media only screen and (max-width: 520px) {
    .blog-hero-bg {
        margin-top: 60px;
        height: 232px;
        width: 100%;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: center !important;

    }

}