.get-rewarded-header {
    margin-top: 64px;
    padding: 64px 0px;
}

.get-rewarded-header h1 {
    font-size: 48px;
    font-weight: 700;
    font-family: "Archivo";
    line-height: 56px;
    color: #141415;
    text-align: center;
}

.steps-card {
    padding: 40px;
    border-radius: 24px;
    background: #ffffff;
}

.steps-card small {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    font-family: "Nunito";
   
}

.steps-card h5 {
    font-size: 32px;
    line-height: 34.82px;
    font-weight: 700;
    font-family: "Archivo";
    margin-top: 24px;
}

.steps-card p {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    font-family: "Nunito";
    margin-top: 12px;
}

@media screen and (max-width: 991px) {
    .get-rewarded-header {
        margin-top: 48px;
        padding: 48px 0px;
    }
    
    .get-rewarded-header h1 {
        font-size: 36px;
    }
    
    .steps-card {
        padding: 20px;
        border-radius: 16px;
        background: #ffffff;
    }
    
    .steps-card small {
        margin-top: 10px !important;
        display: inline-block;
       
    }
    
    .steps-card h5 {
        font-size: 28px;
        line-height: 34.82px;
        font-weight: 700;
        font-family: "Archivo";
        margin-top: 24px;
    }
    
    .steps-card p {
        font-size: 20px;
    }
    .hide-for-tab{
        display: none;
    }
    .column-reverse-in-tab{
        display: flex;
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 520px) {
    .get-rewarded-header {
        margin-top: 48px;
        padding: 36px 0px;
    }
    
    .get-rewarded-header h1 {
        font-size: 24px;
    }
    
    .steps-card {
        padding: 10px;
        border-radius: 8px;
    }
    
    .steps-card small {
        margin-top: 10px !important;
        display: inline-block;
       
    }
    
    .steps-card h5 {
        font-size: 24px;
        line-height: 34.82px;
        font-weight: 700;
        font-family: "Archivo";
        margin-top: 16px;
    }
    
    .steps-card p {
        font-size: 18px;
    }
    .hide-for-tab{
        display: none;
    }
    .column-reverse-in-tab{
        display: flex;
        flex-direction: column-reverse;
    }
}