.youtube-hero-section {
    background-image: linear-gradient(#ff8ab90b, #b8c2ff2b), url("../../../assets/focii-youtube/hero-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
    margin-top: 75px;
    display: flex;
    align-items: center;
    padding: 96px 0px;
    min-height: 80vh;
}

.youtube-hero-left-section h1 {
    font-family: "Archivo";
    font-weight: 800 !important;
    font-size: 64px !important;
    line-height: 76.8px;
    color: #303031;
}

.transparent-text-color {
    background: linear-gradient(to top right, #1F3DFF, #ff1f79bd);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.youtube-hero-left-section p {
    font-family: "Nunito";
    font-weight: 500;
    font-size: 24px !important;
    line-height: 33.6px;
    color: #4C4C4D;
}

.youtube-hero-left-section button {
    border: 0;
    padding: 16px 40px;
    border-radius: 12px;
    background: #141415;
    color: #FFFFFF;
    font-size: 18px;
    font-family: "Archivo";
    letter-spacing: 0.5px;
    margin-top: 20px;
}

.youtube-hero-left-section button span {
    color:
        #FF1F78;
}

.youtube-hero-left-section img {
    margin-top: 50px;
}

@media screen and (max-width: 520px) {
    .youtube-hero-section {
        background-size:contain;
        background-repeat: no-repeat;
        margin-top: 50px;
        padding: 48px 0px;
    }

    .youtube-hero-left-section h1 {
        font-weight: 700 !important;
        font-size: 38px !important;
        line-height: 48.8px;
  
    }

    .youtube-hero-left-section p {
        font-family: "Nunito";
        font-size: 20px !important;
        line-height: 30.6px;
        margin-top: 15px;
    }

    .youtube-hero-left-section button {
        padding: 8px 40px;
        border-radius: 6px;
        font-size: 16px;
        margin-top: 20px;
        width: 100%;
    }

    .youtube-hero-left-section button span {
        color:
            #FF1F78;
    }

    .youtube-hero-left-section img {
        margin-top: 50px;
    }

    .hide-for-mobile {
        display: none;
    }
}