@media screen and (min-width: 1330px) {
    .contact-container {
        margin-top: 4rem;
        display: flex;
        align-items: center;
        height: 91vh;
    }
}

@media screen and (max-width: 1329px) {
    .contact-container {
        display: flex;
        align-items: center;
        height: auto;
        margin-top: 5rem;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
textarea {
    resize: none;
  }

.contact-hello {
    font-weight: 700;
    font-size: 64px;
    line-height: 76px;
    margin: 0;
    margin-top: 16px;
    font-family: Archivo;
}

.love-to-hear {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    margin: 0;
    margin-top: 16px;
}


.contact-description {
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    text-align: justify;
    margin: 0;
    margin-right: 70px;
    margin-top: 16px;
}


.contact-form-div {
    padding: 24px;
    border-radius: 24px;
    margin-top: 2rem;
}

.lets-talk {
    font-weight: 700;
    font-size: 32px;
    margin: 0;
    padding: 0;
}
.number-input-field{
    width: 75%;
}

.input-underline input {
    background: none;
    border: 1px solid #141415;
    border-radius: 4px;
    padding: 8px 24px;
    height: 48px;
    width: 100%;
    color: #141415;
}

input:focus {
    color: #141415;
}

.input-underline select {
    background: none;
    border: 1px solid #141415;
    border-radius: 4px;
    padding: 10px 20px;
    height: 48px;
    width: 100%;
    color: #141415;
}

.contact-input-label {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-top: 24px;
    margin-bottom: 5px;
}

input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #717384;
}



.input-underline textarea {
    border: 1px solid #141415;
    background: 0;
    color: #141415;
    margin-top: 24px;
    border-radius: 4px;
    width: 100%;
    height: 100px;
    padding: 8px 24px;
    margin: 0;
}

.input-underline textarea:focus {
    color: #141415;
}

option {
    color: #141415;
}

option::selection {
    color: #141415 !important;
}



.submit-btn {
    width: 97%;
    height: 60px;
    position: relative;
    left: 1.75%;
    border: 0;
    border-radius: 4px;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    padding: 6px 12px;
    margin: 0;
    margin-top: 20px;
}


.validation-text {
    color: rgba(255, 0, 0, 0.866);
    font-size: 13px;
}



@media only screen and (max-width: 768px) {
    .contact-container {

        height: auto;
        display: flex;
        align-items: center;
    }

    .contact-hello {
        margin-top: 4rem;
    }

    .contact-form-div {
        margin: 0;
        padding: 24px;
        margin-top: 3rem;
    }
    .number-input-field{
        width: 85%;
    }
}

@media only screen and (max-width: 520px) {
    .contact-container {
        margin-top: 0rem;
        display: flex;
        align-items: center;
        height: auto;
    }

    .contact-section {
        width: 100%;
        margin-left: 25px;
        margin-right: 25px;

    }

    .contact-div {
        padding: 1rem;
    }

    .contact-hello {
        font-weight: 700;
        font-size: 3rem;
        line-height: 1.1;
        letter-spacing: -.2rem;
        margin-top: 6rem;
    }

    .love-to-hear {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;

        margin: 0;
        margin-top: 16px;
    }

    .contact-description {
        line-height: 25px;
        font-size: 20px;

        font-weight: 400;
        opacity: .9;
        width: 100%;
    }

    .contact-form-div {
        margin: 0;
        padding: 20px;
        margin-top: 3rem;
        border-radius: 12px;
    }

    .submit-btn {
        width: 95%;
        height: 50px;
        position: relative;
        left: 2.5%;
        border: 0;
        border-radius: 4px;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        padding: 6px 12px;
        margin: 0;
        margin-top: 20px;
    }
    .number-input-field{
        width: 100%;
    }
}

@media only screen and (max-width: 375px) {

    .contact-hello {
        font-size: 2.8rem;
        margin-top: 8rem;
    }
}