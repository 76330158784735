.popular-article-bg {
    padding: 48px 0px;
}

.popular-article-container {
    width: 95%;
}

.popular-articles-heading {
    font-size: 24px;
    line-height: 32px;
    padding-left: 20px;
    font-family: Nunito;
    font-weight: 500;
}


.popular-article-div {
    border-radius: 8px;
    margin-left: 7%;
    padding: 24px 16px 16px;
    width: 302px;
    height: 395px;
}

.swiper-button-next {
    color: rgba(15, 15, 15, 0.527) !important;
    margin-right: -12px;
    display: none !important;
}

.swiper-button-prev {
    color: rgba(10, 7, 7, 0.437) !important;
    margin-left: -12px;
    z-index: 3 !important;
    display: none !important;
}

@media only screen and (max-width: 520px) {
    .popular-article-div {
        border-radius: 8px;
        margin: .8rem;
        padding: 24px 16px 16px;
        width: 87%;
        height: 400px;
        margin-left: 7%;
    }

    .popular-article-bg {
        padding: 24px 0px;

    }

    .swiper-button-next {
        color: rgba(15, 15, 15, 0.527) !important;
        margin-right: -12px;
    }
    
    .swiper-button-prev {
        color: rgba(10, 7, 7, 0.437) !important;
        margin-left: -12px;
        z-index: 3 !important;
    }
}

@media only screen and (max-width: 395px) {
    .popular-article-div {
        width: 86%;

    }
}

@media only screen and (max-width: 320px) {
    .popular-article-div {
        border-radius: 8px;
        margin: .8rem;
        padding: 24px 16px 16px;
        width: 290px;
        height: 400px;
        margin-left: 3%;
    }
}

.popular-card-content {
    height: 88%;
}

.card-number {
    width: 44px;
    height: 44px;
    padding: 11px 3px 10px 2px;
    background: #717384;
    border-radius: 92px;
    position: relative;
    left: 40%;
    bottom: 50px;
    opacity: .9;

}

.popular-article-card-titile {
    font-size: 20px;
    line-height: 28px;
    margin-top: -2rem;
}

.popular-dynamic-article-card-titile {
    font-size: 20px;
    line-height: 28px;
    margin-top: -0.5rem;
}

.popular-read-more {
    margin-top: -.5rem;
    font-size: 12px;
    background: linear-gradient(125.69deg, #0C8AE6 0%, #C21E2C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.popular-dynamic-read-more {
    margin-top: -.5rem;
    font-size: 12px;
    background: linear-gradient(125.69deg, #0C8AE6 0%, #C21E2C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: -1rem;
}

.popular-read-more-text {
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    font-family: Nunito;
    border: 0;
}

.read-more-text-overflow {
    height: 235px;
    overflow-y: hidden;
    position: relative;
    bottom: 30px;
}

.read-more-text-overflow2 {
    height: 200px;
    overflow-y: hidden;
    position: relative;
    bottom: 30px;
}

.soap-color-btn {
    background: #D2CDF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}


.robin-egg-blue-btn {
    background: #CDDBF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.champagne-color-btn {
    background: #F4E6CD;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.date-div {
    margin-bottom: -20px;
    margin-top: -10px;
}

.date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #CACACE;
}

.popular-dot {
    font-size: 30px;
    position: relative;
    bottom: 22px;
    padding-left: 10px;
    padding-right: 10px;
    opacity: .8;
}