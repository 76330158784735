.brain-wave-container {
    margin-top: 60px;
    margin-bottom: 50px;
}

.brain-wave-container h1 {
    font-family: Archivo;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 32px;
}

.brain-wave-card {
    height: 325px;
    width: 100%;
    padding: 48px;
    border-radius: 12px;
    margin-top: 20px;
}

.brain-wave-card img {
    margin-bottom: 50px;
    height: 100px;
    width: 100%;
}


.brain-wave-card h2 {
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
    padding-top: 50px;
}

.brain-wave-card p {
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

@media screen and (max-width:768px) {
    .brain-wave-container {
        margin-top: 0px;
    }

    .brain-wave-container h1 {
        font-family: Archivo;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
    }

    .brain-wave-card {
        height: auto;
        width: 100%;
        padding: 24px;
        border-radius: 12px;
        /* background: #1A1A1B; */
        margin-top: 20px;
    }
}

@media screen and (max-width:520px) {
    .brain-wave-container {
        margin-top: 0px;
    }

    .brain-wave-container h1 {
        font-family: Archivo;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    .brain-wave-card {
        height: auto;
        width: 100%;
        padding: 24px;
        border-radius: 12px;
        /* background: #1A1A1B; */
        margin-top: 20px;
    }

    .brain-wave-card p {
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }
}