.carousel-heading-text h1 {
    font-family: Archivo;
    font-size: 48px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 100%;
    text-transform: uppercase;
}

.carousel-heading-text p {
    text-align: justify;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-top: 16px;
}

.brain-angle-title {
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
}

.brain-angle-view-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 317px !important;
    height: 227px;
    border-radius: 16px;
    padding: 24px 16px;
    margin-top: 24px;
    cursor: pointer;
}

.activated-brain-tab {
    background: #F7F7F7;
}

.swiper-image {
    height: 300px;
    width: 100% !important;
    cursor: pointer;
}


.swiper-pagination-image {
    height: 40px;
    width: 65px;
    cursor: pointer;
}

.swiper-images-div {
    margin-top: 4rem;
}

@media screen and (max-width: 768px) {
    .carousel-container {
        margin-top: -54px !important;
    }

    .carousel-heading-text h1 {
        font-family: Archivo;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
    }

    .carousel-heading-text p {
        text-align: justify;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-top: 10px;
    }

    .brain-angle-view-div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 308px !important;
        height: 100px;
        border-radius: 16px;
        background: none;
        padding: 24px 16px;
        margin-top: 24px;
        cursor: pointer;

    }

    .activated-brain-tab {
        background: #F7F7F7;
    }

    .tab-device-flex {
        display: flex;
        justify-content: center;
        gap: 16px;
    }
}

@media screen and (max-width: 520px) {
    .carousel-container {
        margin-top: -80px !important;
        margin-bottom: 20px;
    }

    .brain-angle-view-div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48% !important;
        height: 80px;
        border-radius: 16px;
        background: none;
        padding: 24px 16px;
        margin-top: 0px;
        cursor: pointer;
    }

    .brain-angle-title {
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
    }

    .activated-brain-tab {
        background: #F7F7F7;
    }

    .tab-device-flex {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .hide-for-mobile-device {
        display: none;
    }

    .show-for-mobile-device {
        width: 100%;
        display: block;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        margin-top: 24px;
    }

    .swiper-pagination-image {
        height: 40px;
        width: 65px;
        cursor: pointer;
    }
}

@media screen and (min-width:521px) {
    .show-for-mobile-device {
        display: none;
    }
}