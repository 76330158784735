 .reward-section {
     background: url("../../assets/reward-laptop.png");
     background-position: center;
     background-size: cover;
     border-radius: 24px;
     padding: 48px;
     margin: 48px 10px;

 }

 .reward-section-container {
     background: #FFF;
     width: 100%;
     border-image: linear-gradient(30deg, #FF5599, #8595FF) 30;
     border-width: 2px 0;
     border-style: solid;

 }

 .reward-section h1 {
     color: #141415;
     font-family: Archivo;
     font-size: 32px;
     font-style: normal;
     font-weight: 700;
     line-height: normal;
     text-transform: uppercase;
     margin-top: 64px;
 }

 .reward-section p {
     color: #141415;
     font-family: Nunito;
     font-size: 18px;
     font-style: normal;
     font-weight: 600;
     line-height: 160%;
 }

 .reward-explore-button {
     display: flex;
     align-items: end;
 }

 .reward-explore-button a {
     text-decoration: none;
     text-align: center;
     background: #141415;
     border: 0;

     padding: 12px 40px;
     width: 100%;
     border-radius: 8px;

     color: #FFF;
     font-family: Archivo;
     font-size: 24px;
     font-style: normal;
     font-weight: 400;
     line-height: 120%;
     letter-spacing: 0.5px;
     text-transform: uppercase;
 }

 .innovation-video-bg {
     background: linear-gradient(#ff8ab914, #b8c2ff21);
     padding: 48px 0px;
 }

 .innovation-video-bg h2 {
     font-size: 36px;
     font-weight: 700;
     font-family: "Archivo";
     text-align: center;
     margin-bottom: 40px;
 }

 .video-responsive {
     overflow: hidden;
     padding-bottom: 56.25%;
     position: relative;
     height: 0;
 }

 .video-responsive iframe {
     left: 0;
     top: 0;
     height: 100%;
     width: 100%;
     position: absolute;
     border-radius: 24px;
 }

 @media screen and (max-width:600px) {
     .reward-section {
         background: url("../../assets/reward-mobile.png");
         background-position: center;
         background-size: cover;
         border-radius: 12px;
         padding: 16px 8px;
         margin-top: 64px;
     }

     .reward-section h1 {
         font-size: 20px;
         margin-top: 32px;
     }

     .reward-explore-button {
         display: flex;
         align-items: end;
     }

     .reward-explore-button a {
         text-decoration: none;
         text-align: center;
         background: #141415;
         border: 0;

         padding: 12px 40px;
         width: 100%;
         border-radius: 8px;
         color: #FFF;
         font-family: Archivo;
         font-size: 16px;
         font-style: normal;
         font-weight: 400;
         line-height: 120%;
         letter-spacing: 0.5px;
         text-transform: uppercase;
         margin-top: 34px;

     }

     .video-responsive iframe {
         border-radius: 12px;
     }

     .innovation-video-bg {
         padding: 24px 0px;
     }

     .innovation-video-bg h2 {
         font-size: 22px;
         font-weight: 600;
         margin-bottom: 20px;
     }
 }

 @media screen and (max-width:800px) {



     .reward-explore-button button {
         text-decoration: none;
         text-align: center;
         background: #141415;
         border: 0;
         height: 56px;
         padding: 12px 40px;
         width: 100%;
         border-radius: 8px;
         color: #FFF;
         font-family: Archivo;
         font-size: 22px;
         font-style: normal;
         font-weight: 400;
         line-height: 120%;
         letter-spacing: 0.5px;
         text-transform: uppercase;
         margin-top: 34px;
     }
 }