.privacy-control-system {
    margin: 48px 0px;
}

.privacy-control-system h1 {
    font-family: "Archivo";
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: #141415;
}

.privacy-control-system h5 {
    font-family: "Archivo";
    font-size: 32px;
    font-weight: 700;
    line-height: 34.82px;
    color: #313135;
    margin-top: 24px;
}
.privacy-control-system p {
    font-family: "Nunito";
    font-size: 20px;
    font-weight: 400,;
    line-height: 34.82px;
    color: #8A8A93;
    margin-top: 24px;
}
.privacy-control-system a {
    font-family: "Nunito";
    font-size: 20px;
    font-weight: 400,;
    line-height: 34.82px;
    color: #000529;
    margin-top: 24px;
}

@media screen and (max-width: 991px) {

    .privacy-control-system h1 {
        font-size: 36px;
        line-height: 36px;

    }
    
    .lock-img {
       margin-top: 30px;
    }
    .privacy-control-system h5 {
        font-family: "Archivo";
        font-size: 24px;
        font-weight: 700;
        line-height: 34.82px;
        color: #313135;
        margin-top: 24px;
    }
    .privacy-control-system p {
        font-size: 20px;
        font-weight: 400,;
        line-height: 32.82px;
        margin-top: 16px;
    }
    .privacy-control-system a {
        font-family: "Nunito";
        font-size: 18px;
        font-weight: 400,;
        line-height: 34.82px;
        color: #000529;
        margin-top: 20px;
    }
}

@media screen and (max-width:520px) {
    .lock-img {
        margin-top: 30px;
        width: 70px;
     }

     .privacy-control-system h1 {
        font-size: 30px;
        line-height: 32px;

    }
    

    .privacy-control-system h5 {
        font-family: "Archivo";
        font-size: 22px;
        font-weight: 700;
        line-height: 30.82px;
        color: #313135;
        margin-top: 24px;
    }
    .privacy-control-system p {
        font-size: 18px;
        font-weight: 400,;
        line-height: 26.82px;
        margin-top: 16px;
    }
    .privacy-control-system a {
        font-family: "Nunito";
        font-size: 18px;
        font-weight: 400,;
        line-height: 34.82px;
        color: #000529;
        margin-top: -10px;
        display: inline-block;
    }
}