.readyBanner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100vh;  */
    padding: 15px 35px;
  }
  
  .readyBanner {
    /* margin-top:64px; */
    max-width: 100%; /* Make sure the image doesn't exceed the container */
    max-height: 100%; /* Make sure the image doesn't exceed the container */
  }
  .firstBanner{
    padding-top: 45px;
}
  .earnBannerClick{
    cursor: pointer;
  }
  .readyBanner img {
    width: 100%;
    height: auto;
  }
  
  @media screen and (max-width:700px) {
    .firstBanner{
        padding-top: 35px;
    }
   
  }
  @media screen and (max-width:500px) {
    .firstBanner{
        padding-top: 22px;
    }
    
  }