.content-center {
    width: 100%;
}

.content-center h5 {
    font-size: 24px;
    color: #141415;
}

.content-center h6 {
    font-size: 20px;
    color: #141415;
}

.content-center p {
    font-size: 14px;
    color: #141415;
}

.content-center b {
    font-weight: bold;
    color: #0c8ae6;
}