* {
    margin: 0px;
    padding: 0px;
}

.text-right {
    text-align: right;
}

body {
    overflow-x: hidden;
}

/* REALOD PAGE */

#loader-main {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    background-color: #e9e9e9;
}

#loader {
    border: 10px solid rgb(255 255 255 / 21%);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    position: absolute;
    left: 50%;
    right: 0;
    top: 50%;
    margin-left: -60px;
    margin-top: -60px;
    bottom: 0;
    -webkit-animation: spin 2s linear infinite;
    -o-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    text-align: center;
    line-height: 98px;
}

#loader img {
    width: 80px;
    height: 80px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Comman CSS Comman CSS Comman CSS*/

body {
    font-size: 15px;
    font-family: 'Archivo', sans-serif;
    color: #000;
    font-family: 'Nunito', sans-serif;

}

.btn-dark {
    color: #fff;
    background-color: #000;
    border-color: #000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Archivo', sans-serif !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #8A8A93;
    font-weight: 400;
    font-size: 16px;
    padding: 4px 12px ;
    font-family: 'Nunito', sans-serif !important;
}
@media screen and (max-width:990px) and (min-width:768px) {
    .navbar-light .navbar-nav .nav-link {
        color: #8A8A93;
        font-weight: 400;
        font-size: 16px;
        padding: 4px 6px !important;
        font-family: 'Nunito', sans-serif !important;
    }
}

.navbar-nav .nav-link.active {
    color: #000;
    font-weight: 700;
    transition: 0.3s;
    position: relative;
}

.navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #000;
}

.navbar-light .navbar-nav .nav-link.active:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: #000;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
}

.navbar-light .navbar-nav .nav-link.active:before {
    visibility: visible;
    width: 40%;
    left: 25%;
}

header {
    position: fixed;
    width: 100%;
    text-align: center;
    transition: all 0.4s ease;
    z-index: 1;
}

header.sticky {
    background: #f7f7f7;
    height: 80px;
    top: 0px;
    box-shadow: 0 10px 20px 0 rgb(0 3 52/27%);
}

.fixed-top {
    background: #fff;
}

header.sticky .fixed-top {
    background: #f7f7f7;
}

/*HOME PAGE CSS HOME PAGE CSS*/


main {
    margin-top: 80px;
    float: left;
    width: 100%;
}

.headerBg {
    background: url("../assets/header-bg.png") no-repeat;
    background-size: cover;
}

.top-section-bg {
    height: 100vh;
    padding-top: 58px;
}

.scroll-top {
    transition: background-color .3s, opacity .5s, visibility .5s;
    z-index: 1000;
    text-decoration: none;
    transform: rotate(270deg);
    color: #000;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    width: 90px;
    position: relative;
    right: 25px;
    background: none !important;
    background-color: none !important;
}

.scroll-to-top {
    background: none !important;
    right: 40px !important;
    bottom: 100px !important;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 7px;
    width: 40px;
    height: 90px;
    box-shadow: none !important;
    border: none;
}

.scroll-top:before {
    position: absolute;
    width: 40%;
    height: 2px;
    background-color: #000;
    content: "";
    top: 14px;
    right: 110%;
    z-index: -200 !important;
    cursor: pointer;
}

.scroll-top.show {
    opacity: 1;
    visibility: visible;
}

.top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    margin: auto;
}

.top-section h1 {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 25px;
    font-family: 'Archivo', sans-serif !important;
    letter-spacing: 1px;
    color: #313135;
}

.top-section p {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 25px;
    font-family: 'Nunito', sans-serif !important;
    color: #313135;
    text-align: center;
    line-height: 30px;
    line-height: 140%;
    max-width: 1320px;

}

.top-section .btn-dark {
    padding: 12px 30px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    width: 230px;
}

.bottom-bg img {
    width: 100%;
}

.bottom-bg {
    width: 80%;
    margin: auto;
}

.media-logo {
    width: 100%;
    background: #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 100px;
    position: relative;
    z-index: 1;
    gap: 30px;
    /* border-top: 1px solid #d8d3d3; */
}

.logo-icon {
    width: 266px;
    height: 84px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-icon img {
    width: 100%;
}

.media-1 {
    width: 80% !important;
}

#button {
    position: fixed;
    bottom: 100px;
    right: -11px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    text-decoration: none;
    transform: rotate(270deg);
    color: #000;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
}

#button:before {
    position: absolute;
    width: 40%;
    height: 2px;
    background-color: #000;
    content: "";
    top: 14px;
    right: 110%;
    z-index: -200 !important;
    cursor: pointer;
}

#button.show {
    opacity: 1;
    visibility: visible;
}


.features {
    padding: 60px 0;
    overflow: hidden;
}

.features h3 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    letter-spacing: 1px;
    font-family: 'Archivo', sans-serif !important;
    color: #313135;
}

.features p {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
    letter-spacing: 1px;
    font-family: 'Nunito', sans-serif !important;
    color: #313135;
    line-height: 140%;
}

.features ul,
.audi-content ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    float: left;
    width: 100%;
}

.features ul li {
    list-style: none;
    color: #8A8A93;
    font-size: 16px;
    font-family: 'Nunito', sans-serif !important;
    font-weight: 500;
    line-height: 37px;
    line-height: 150%;
    padding-bottom: 20px;
}

.audi-content ul li {
    list-style: none;
    color: #8A8A93;
    font-size: 14px;
    font-family: 'Nunito', sans-serif !important;
    font-weight: 500;
    line-height: 37px;
    line-height: 150%;
    padding-bottom: 20px;
}

.features ul li span,
.audi-content ul li span {
    position: relative;
    left: 5%;
}

.features ul li .bi-check img,
.audi-content ul li .bi-check img {
    width: 20px;
    position: relative;
    top: -4px;
    margin-right: 8px;
}

.features .learn-more {
    font-weight: 600;
    font-size: 24px;
    margin-top: 20px;
    display: inline-block;
    color: #000;
    text-decoration: none;
}

.features .learn-more .bi-check img {
    margin-left: 5px;

}

.autumn {
    /* float: left; */
    width: 100%;
    background: #F7F7F7;
    padding: 60px 0px;
    z-index: 1;
    position: relative;
    /* border-bottom: 1px solid #d8d3d3; */

}

.autumn-text {
    padding: 48px 60px 48px 60px;
    background: #fff;
    border-radius: 24px;
    color: #4E4E55;
    font-family: 'Nunito', sans-serif !important;
    font-size: 28px;
    font-weight: 400;
    line-height: 38px;
    text-align: justify;
    height: 344px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    text-decoration: none;
    cursor: pointer;
}

.autumn-text:hover {
    color: #4E4E55;
    text-decoration: underline;
}

.autumn-text h3 {
    font-family: 'Archivo', sans-serif !important;
    font-weight: 700;
    font-style: italic;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    margin-top: 32px;
    width: 100%;
    margin-bottom: 0;
}

@keyframes slides {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.logos-media {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    padding-top: 50px;
}

.logos-media:before,
.logos-media:after {
    position: absolute;
    top: 0;
    content: '';
    height: 100%;
    z-index: 2;
}

.logos-media:before {
    left: 0;
}

.logos-media:after {
    right: 0;
}

.logo_items {
    display: inline-block;
    animation: 50s slides infinite linear;
}

.logos-media:hover .logo_items {
    animation-play-state: paused;
}

.logo_items img {
    height: 85px;
    width: 300px;
}

.testimonial {
    float: left;
    width: 100%;
    padding-top: 80px;
    z-index: 0;
    position: relative;
}

.box-testimonial {
    position: relative;
    /* width: 100%; */
    background: #f7f7f7;
    padding: 32px 48px 32px 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 40px 25px;
    height: 440px;
    border-radius: 24px;
}

.box-testimonial .photo {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 25px;
}

.box-testimonial .testimonial-text {
    color: #313135;
    font-family: 'Nunito', sans-serif !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
}

.box-testimonial .name {
    color: #313135;
    font-family: 'Archivo', sans-serif !important;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    line-height: 23px;
    margin-bottom: 0px;
}

.owl-carousel .box-testimonial .photo-qut {
    position: absolute;
    left: -8px;
    top: -15px;
    width: 45px;
}

.owl-carousel .box-testimonial .photo-qut-1 {
    position: absolute;
    right: -20px;
    bottom: -10px;
    width: 45px;
}

.reasoly-box {
    padding: 40px 55px 40px 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F7F7F7;
    border-radius: 24px;
    margin-top: 22px;

}

.reasoly-box h2 {
    color: #313135;
    font-family: 'Archivo', sans-serif !important;
    font-weight: 500;
    font-size: 32px;
    text-align: left;
    line-height: 23px;
    margin-bottom: 0px;
    line-height: 48px;
}

.red-section {
    float: left;
    width: 100%;
    padding-top: 40px;
}

.reasoly-box .btn-dark {
    padding: 10px 42px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 0.25rem rgb(66 70 73 / 0%) !important;
}

.btn-dark a {
    color: #fff;
    text-decoration: none;
}


/***** Footer Footer Footer *******/

.footer {
    background: #F7F7F7;
    color: #4E4E55;
    float: left;
    width: 100%;
    margin-top: 64px;

}

.footer .links ul {
    list-style-type: none;
}

.footer .links li a {
    color: #4E4E55;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 0px;
    float: left;
    width: 100%;
}

.footer .links li a:hover {
    text-decoration: underline;
    color: #000;
}

.footer .about-company a {
    color: #4E4E55;
    transition: color 0.2s;
}

.footer .about-company a:hover {
    color: #4E4E55;
}

.footer .location i {
    font-size: 18px;
}

.footer .copyright p {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.bhi-logo img {
    width: 150px;
    margin-bottom: 20px;
}

.bhi-logo-socail a {
    list-style: none;
    text-decoration: none;
}

.bhi-logo-socail a img {
    width: 34px;
}

.footer h4 {
    font-size: 16px;
    color: #8A8A93;
    font-weight: 500;
    font-family: 'Nunito', sans-serif !important;
}

.bhi-logo-socail {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.footer .about-company a {
    color: #4E4E55;
    transition: color 0.2s;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
}

.about-company p {
    float: left;
    font-weight: 600;
    color: #8A8A93;
    font-size: 14px;
}

.smallFocii {
    color: #8A8A93;
    /* color: #CACACE; */
    font-family: "Archivo";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin-bottom: 12px;
}

.focii-section h1 {
    color: #313135;
    font-family: 'Archivo', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
}

.focii-section p {
    color: #313135;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    margin-bottom: 32px;
}

.focii-section {
    display: flex;
    align-items: center;
    height: 100vh;

}

.focii-section .btn-dark {
    padding: 12px 46px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 8px;
}

.audi-content {
    padding: 60px 0;
    overflow: hidden;
}

.audi-content h4 {
    color: #313135;
    font-size: 24px;
    font-weight: 600;
    line-height: 140%;
    font-family: 'Archivo', sans-serif;
}

.audi-content p {
    color: #8A8A93;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
}

.video-text {
    color: #313135;
    font-family: 'Archivo', sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 140%;
    padding-right: 40px;
}

.video-box {
    display: flex;
    width: 100%;
    /* height: 400px; */
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: #F7F7F7;
}

#bannerVideo {
    width: 100%;
    height: auto;
    border-radius: 24px;

}

.desk-meeting {
    padding: 80px 0 50px;
    float: left;
    width: 100%;
}

.desk-meeting h3 {
    color: #313135;
    font-family: 'Archivo', sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 140%;
    /* 50.4px */
    text-align: center;
    width: 50%;
    margin: auto;

}

.desk-meeting .boxes {
    border-radius: 24px;
    background: #F7F7F7;
    margin-top: 64px;
    margin-bottom: 2px;
    padding: 32px;
}

.desk-meeting .boxes p {
    color: #8A8A93;
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 150%;
    padding-top: 32px;
}

.abha-padding {
    padding-right: 74px;
}

.b-radius {
    border-radius: 20px;
}

.abha-section h1 {
    color: #313135;
    font-family: 'Archivo', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
}

.abha-section p {
    color: #313135;
    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    margin-bottom: 32px;
}

.abha-section {
    display: flex;
    align-items: center;
    height: 92vh;

}

.abha-section .btn-dark {
    padding: 12px 46px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 8px;
}

.mobility {
    margin: auto;
    text-align: center;
    padding: 80px 0 0;
    width: 78%;
}

.mobility h2 {
    color: #303031;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    /* 116.667% */
}

.mobility p {
    color: #4C4C4D;
    text-align: justify;
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 140%;
    /* 33.6px */
    margin-bottom: 120px;
}

.autumn .cards {
    border-radius: 8px;
    background: #fff;
    padding: 24px;
    margin-top: 48px;
}

.autumn .cards h4 {
    color: #313135;
    font-family: 'Archivo', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.autumn .cards p {
    color: #8A8A93;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 150%;
    /* 24px */
}

.abha-product .audi-content {
    padding: 0 0 80px;
}

.abha-product .autumn h2 {
    color: #303031;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
}


.about-section h1 {
    color: #FFF;
    font-family: Archivo;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    text-align: left;
    margin-top: 24px;
}

.about-section {
    display: flex;
    align-items: center;
    margin-top: 74px;
    padding: 128px;
    background: url("../assets/team/about-us-hero-bg.png");
    background-position: center;
    background-size: cover;
}

.vision {
    border-radius: 24px !important;
}

.vision p {
    color: #141415;
    text-align: justify;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    padding-top: 22px;
    line-height: 150%;
    /* 36px */
}

.vision h3 {
    color: #141415;
    font-family: 'Archivo', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.vision-content {
    padding: 40px 24px;
    background: #F7F7F7;
    /* border-radius: 24px !important; */
}

.vision img {
    width: 100%;
}

.teamMember h2 {
    color: #141415;
    font-family: 'Archivo', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 22px;
}

.teamMember p {
    color: #141415;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.teamMember h3 {
    color: #141415;
    font-family: 'Archivo', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 52.8px */
    margin-bottom: 32px;
}

.team-name {
    color: #141415;
    font-family: 'Archivo', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 24px;
}

.designation {
    color: #141415;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 6px;
}

.teamMember {
    padding: 80px 0 64px;

}

.team-grid {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 48px;
    align-self: stretch;
    flex-wrap: wrap;
}

.team {
    padding-top: 64px;
}

.team .cards {
    width: 22.00%;
}

.team .cards img {
    width: 100%;
}

.owl-carousel .owl-item img.headsetImg {
    width: 83%;
    padding-top: 9rem;
}

.key-head {
    color: #141415;
    font-family: 'Archivo', sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 38px;
    padding-bottom: 32px;
}

.key-feature .cards {
    border-radius: 16px;
    background: #F7F7F7;
    height: 100%;

}

.key-feature .col-md-6 {
    padding-top: 48px;
}

.key-feature h2 {
    color: #141415;
    text-align: center;
    font-family: 'Archivo', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.key-feature .cards .card-body {
    padding: 24px 24px 24px 32px
}

.key-feature .cards p {
    color: #848485;
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 140% */
    margin-bottom: 0;


}

.key-feature .cards ul {
    padding-top: 32px;
    color: #848485;
    text-align: justify;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}

.key-feature .cards img {
    width: 100%;
    border-radius: 16px 16px 0 0
}

.headset-card {
    border-radius: 24px;
    background: #FFF;
    padding: 32px;
    margin-top: 64px;
}

.ba9e-cap {
    align-items: center;
    gap: 64px;
    align-self: stretch;
    justify-content: space-between;
    display: flex;
}

.headset-card h4 {
    color: #313135;
    font-family: 'Archivo', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 28px;
    /* 70% */
    margin-bottom: 40px;
}

.headset-card h6 {
    color: #6C6C75;
    font-family: 'Archivo', sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 24px;
    line-height: 28px;
    /* 116.667% */
}

.headset-card ul li {
    color: #848485;
    text-align: justify;
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 15px;
    line-height: 128%;
    /* 25.6px */
}

.headset-features {
    display: flex;
    padding-bottom: 40px;
    align-items: flex-start;
}

.headset-features:last-child {
    padding-bottom: 0;
}

.headset-features .feature-logo {
    border-radius: 56px;
    background: #313135;
    padding: 8px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.features-content {
    padding-left: 18px;
}

.imageSet {
    width: 95%;
}

.headset-features .feature-logo img {
    width: 30px;
}

.electrode-f {
    margin-top: 64px;
}


.nav-tabs .nav-link {
    color: #8A8A93;
    text-align: center;
    font-size: 24px;
    padding: 22px 40px;
    font-weight: 400;
    line-height: 22px;
    /* 91.667% */
}

.nav-tabs .nav-link.active span {
    font-weight: 700;
}

.nav-tabs {
    border-radius: 24px;
    background: #FFF;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.08);
    width: 617px;
    margin: auto;
    text-align: center;
    padding: 8px;
    color: #4E4E55;
    text-align: center;
    justify-content: center;
}

.button {
    border-radius: 16px;

}


.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus {
    border-color: transparent;
    border-radius: 16px;
    background: #F4F4F5;
}

.headset-features h2 {
    color: #313135;
    text-align: justify;
    font-family: 'Archivo', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    /* 135.714% */
}

.headset-features p {
    color: #848485;
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 28px;
}

.ba3e-features {
    padding: 112px 0 48px;
}

.ba3e-features h2 {
    color: #313135;
    text-align: justify;
    font-family: 'Archivo', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
}

.ba3e-features p {
    color: #848485;
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 28px;
    text-align: center;
    padding: 0 30px 40px;
}

.key-feature {
    padding-top: 64px;
    margin-top: 60px;
    overflow: hidden;
}

.comingSoon {
    border-radius: 8px;
    padding: 6px 16px;
    background: #6C6C75;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    border: 0;
    line-height: 32px;
}

.eeg-cap h4 {
    padding-top: 64px;
}

.mob-width {
    padding-right: 55px;
}