* {
  margin: 0;
  padding: 0;
}

/* classes for dark background color- */
.neutral-black-bg {
  background: #000000;
}

.gray-background-bg {
  background: #141415;
}

.primary-black-fade-bg {
  background: #1a1a1b;
}

.secondary-black-fade-bg {
  background: #151515;
}

/* classess for light background color- */
.primary-light-bg {
  background: #ffffff;

}

.secondary-light-bg {
  background: #f7f7f7;
  height: auto;
}

.colourful-bg {
  background: #0c8ae6;
}

/* classess for dark text color- */
.colourful-text {
  color: #0c8ae6;
}

.neutral-black-color {
  color: #000000;
}

.primary-dark-text {
  color: #141415;
}

.secondary-dark-text {
  color: #313135;
}

.black-gray {
  color: #4E4E55;
}

.dark-gray {
  color: #717384;
}

/* classess for light text color- */
.primary-light-text {
  color: #ffffff;
}

.secondary-light-text {
  color: #cacace;
}

.light-gray-text {
  color: #fafafa;
}