.publication-heading {
    font-family: Archivo;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 32px;
}

.publications-card {
    height: 260px;
    padding: 24px;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    cursor: pointer;
}

.publications-card p {
    text-align: justify;
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    opacity: .9;
}

#publications-link-text {
    text-align: justify;
    font-family: Nunito;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    text-decoration-line: underline;
    opacity: .8;
}

.publications-card small {
    text-align: right;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: block;
    margin-top: 20px;
}

@media screen and (max-width: 769px) {
    .publications-card {
        height: auto;
        padding: 24px;
        border-radius: 8px;

        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 16px;
    }

    .publication-heading {
        font-family: Archivo;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 20px;
        margin-top: -40px;
    }
}

@media screen and (max-width: 520px) {
    .publications-card {
        height: auto;
        padding: 24px;
        border-radius: 8px;

        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 16px;
    }

    .publication-heading {
        font-family: Archivo;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 20px;
        margin-top: -40px;
    }
}

@media screen and (min-width:1200px) and (max-width: 1400px) {
    .publications-card {
        height: 290px;
    }
}

@media screen and (min-width:980px) and (max-width: 1199px) {
    .publications-card {
        height: 330px;
    }
}