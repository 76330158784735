@media only screen and (max-width: 1400px) {
    .abha-section h1 {
        font-size: 60px;
    }
}

@media only screen and (max-width: 1300px) {
    .reasoly-box h2 {
        font-size: 26px;
    }

    .reasoly-box .btn-dark {
        font-size: 21px;
    }

    .autumn-text {
        font-size: 26px;
    }

    .desk-meeting .boxes p {
        font-size: 21px;
    }

    .focii-section p {
        font-size: 15px;
    }

    .focii-section h1,
    .abha-section h1 {
        font-size: 58px;
    }

    .features ul li,
    .audi-content ul li {
        font-size: 14px;
    }

    .features .learn-more {
        font-size: 20px;
    }

    .audi-content h4 {
        font-size: 22px;
    }

    .audi-content p {
        font-size: 14px;
    }

    .abha-section p {
        font-size: 22px;
    }

    .abha-padding {
        padding-right: 35px;
    }

    .nav-tabs {
        justify-content: space-between;
    }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 1200px) {
    .autumn .cards p {
        font-size: 14px;
    }

    .autumn .cards h4 {
        font-size: 16px;
    }

    .mobility h2 {
        font-size: 42px;
    }



    .mobility p {
        font-size: 21px;
    }

    .abha-section p {
        font-size: 18px;
    }

    .abha-section .btn-dark {
        padding: 10px 32px;
        font-size: 14px;
    }

    .key-feature .cards ul {
        font-size: 17px;
    }

    .key-head {
        font-size: 24px;
        line-height: 30px;
    }

    .key-feature .cards p {
        font-size: 17px;
        line-height: 25px;
    }

    .headset-card ul li {
        font-size: 17px;
    }

    .headset-card h6 {
        font-size: 22px;
    }

    .headset-card h4 {
        font-size: 32px;
    }

    .headset-features p {
        font-size: 17px;
        line-height: 25px;
    }

    .headset-features h2 {
        font-size: 24px;
        line-height: 38px;
    }



    .focii-section p {
        font-size: 13px;
    }

    .smallFocii {
        font-size: 28px;
    }

    .focii-section .btn-dark {
        padding: 12px 42px;
        font-size: 14px;

    }

    .desk-meeting h3 {
        width: 70%;
    }

    .desk-meeting .boxes {
        min-height: 552px;
    }

    .about-section h1 {
        font-size: 41px;
    }

    .teamMember h2 {
        font-size: 70px;
        margin-bottom: 20px;
    }

    .team {
        padding-top: 78px;
    }

    .team .cards {
        width: 29.32%;
    }

    .team-name,
    .designation {
        font-size: 20px;
    }

    .teamMember p {
        font-size: 20px;
    }

    .top-section {
        width: 90%;
    }

    .bottom-bg {
        width: 86%;
    }

    .reasoly-box h2 {
        line-height: 150%;
        font-size: 22px;
    }

    .reasoly-box .btn-dark {
        font-size: 20px;
    }

    .box-testimonial {
        height: 515px;

    }

    .autumn-text {
        font-size: 22px;
    }

    .autumn-text h3 {
        font-size: 16px;
    }

    .features h3 {
        font-size: 26px;
    }

    .features p {
        font-size: 19px;
    }

    .mob-width {
        padding-right: 0;
    }

    .features .learn-more {
        font-size: 17px;
    }
}

@media only screen and (min-device-width: 800px) and (max-device-width: 1000px) {
    .autumn .cards {
        height: 190px;
    }

    .reasoly-box .btn-dark {
        padding: 12px 15px;
        width: 100%;
        max-width: 180px;
        margin-left: 15px;
    }
}

@media screen and (max-width:1000px) {
    .abha-section {
        height: auto;
        /* align-items: flex-end; */
    }

    .top-section-bg {
        height: auto;
        padding-top: 100px;
    }
    .owl-carousel .owl-item img.headsetImg{
        padding-top: 2rem;
    }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 900px) {
    .autumn .cards h4 {
        font-size: 13px;
    }

    .autumn .cards p {
        font-size: 11px;
    }

    .autumn .cards {
        padding: 18px 10px;
    }

    .abha-padding {
        padding-bottom: 50px;
        padding-right: 0;
    }

    .ba3e-features .row.justify-content-between,
    .headset-card .row {
        text-align: center;
    }

    .mobility p {
        font-size: 22px;
    }

    .mobility h2 {
        font-size: 44px;
    }

    .mobility {
        width: 98%;
    }

    .ba3e-features h2 {
        text-align: center;
        text-align: left;
    }

    .ba3e-features p {
        text-align: left;
        padding-left: 0;
    }



    .owl-carousel .owl-item img.headsetImg {
        width: 60%;
        padding-top: 1rem;
        margin: auto;
    }

    .abha-section h1 {
        font-size: 52px;
    }

    .abha-section p {
        font-size: 17px;
    }

    .abha-product .abha-section h1 {
        font-size: 47px;
    }

    .smallFocii {
        font-size: 28px;
    }

    .headset-card ul li {
        font-size: 17px;
    }

    .headset-card h6 {
        font-size: 22px;
    }

    .headset-card h4 {
        font-size: 32px;
    }

    .audi-content p {
        font-size: 16px;
    }

    .audi-content h4 {
        font-size: 24px;
    }

    .desk-meeting .boxes p {
        font-size: 19px;
    }

    .desk-meeting .boxes {
        min-height: 470px;
    }

    .desk-meeting h3 {
        font-size: 32px;
        width: 80%;
    }

    .team .cards {
        width: 28.32%;
    }

    .about-section h1 {
        font-size: 24px;
    }

    .team {
        padding-top: 88px;
    }

    .designation {
        font-size: 16px;
    }

    .team-name {
        font-size: 20px;
    }

    .teamMember p {
        font-size: 16px;
    }

    .teamMember h2 {
        font-size: 55px;
        margin-bottom: 20px;
    }

    .top-section h1 {
        font-size: 40px;
    }

    .top-section p {
        font-size: 21px;
    }

    .top-section {
        width: 82%;
    }

    .bottom-bg {
        width: 100%;
    }

    .bottom-bg {
        width: 100%;
        margin: 28px auto;
    }

    .logo-icon img {
        width: 94%;
    }

    .ipad-wth {
        width: 65%;
    }

    .logo-icon {
        height: auto;
    }

    .autumn-text {
        padding: 35px 60px 35px 60px;
        line-height: 28px;
        font-size: 19px;
    }

    .autumn-text h3 {
        font-size: 16px;
    }

    .logo_items img {
        width: 200px;
        height: auto;
    }

    .box-testimonial .testimonial-text {
        font-size: 13px;
        line-height: 20px;
    }

    .box-testimonial {
        padding: 32px 15px;
    }

    .box-testimonial .photo {
        height: 130px;
    }

    .reasoly-box .btn-dark {
        padding: 12px 15px;
        font-size: 19px;
        width: 100%;
        max-width: 180px;
        margin-left: 15px;
    }

    .reasoly-box h2 {
        font-size: 24px;
        line-height: 34px;
    }

    .eeg-cap h4 {
        font-size: 24px;
    }

    .comingSoon {
        padding: 14px 26px;
        font-size: 20px;
        line-height: 30px;
    }

    .key-feature h2 {
        font-size: 34px;
    }

    .key-head {
        font-size: 26px;
    }

    .media-logo {
        padding: 16px;
        gap: 15px;
    }

}

@media only screen and (max-width: 600px) {
    #bannerVideo {
        width: 98%;
        border-radius: 16px;
    }

    .nav-tabs {
        justify-content: space-between;
    }

    .abha-section {
        /* align-items: flex-end; */
        height: auto;
    }

    .owl-carousel .owl-item img.headsetImg {
        width: 96%;
        padding-top: 45px;
        margin-bottom: -8px;
    }

    .ba3e-features h2 {
        font-size: 18px;
        text-align: left;
        line-height: 140%;
    }

    .ba3e-features img {
        width: 80%;
        margin: auto;
        margin-bottom: 32px;
    }

    .ba3e-features {
        padding: 95px 28px 16px;
        text-align: center;
    }

    .ba3e-features p {
        font-size: 14px;
        padding: 0 0 24px;
        line-height: 150%;
        text-align: left;
    }

    .logo-icon {
        height: auto;
    }

    .headset-card h4,
    .headset-card h6 {
        font-size: 18px;
    }

    .eeg-cap h4 {
        padding-top: 16px;
        margin-bottom: 20px;
    }

    .eeg-cap .btn-dark {
        padding: 12px 30px;
        font-size: 16px;
        width: 100%;
    }

    .headset-card h6 {
        margin-bottom: 24px;
    }

    .comingSoon {
        padding: 8px 26px;
        font-size: 16px;
        width: 100%;
    }

    .headset-features h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 140%;
        text-align: left;
    }

    .headset-features p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .headset-features .feature-logo {
        width: 44px;
        height: 44px;
        margin-left: 5px !important;
    }

    .headset-features .feature-logo img {
        width: 24px;

    }

    .imageSet {
        margin-bottom: 20px;
    }

    .key-feature .cards p,
    .key-feature .cards ul {
        font-size: 14px;
        line-height: 150%;
    }

    .key-head {
        font-size: 16px;
        line-height: 140%;
        font-weight: 700;
        padding-bottom: 16px;
    }

    .key-feature h2 {
        font-size: 24px;
        font-weight: 700;
    }

    .testimonial .owl-theme .owl-dots .owl-dot.active span {
        background: #141415;
        width: 24px;
    }

    .box-testimonial {
        margin: 12px 10px;
        border-radius: 12px;

    }

    .testimonial .owl-theme .owl-dots .owl-dot span {
        border-radius: 8px;
        height: 8px;
        margin: 5px 2.5px;
    }

    .testimonial {
        padding-top: 48px;
    }

    .owl-carousel .box-testimonial .photo-qut {
        position: absolute;
        left: -8px;
        top: -13px;
        width: 35px;
    }

    .owl-carousel .box-testimonial .photo-qut-1 {
        position: absolute;
        right: -10px;
        bottom: -13px;
        width: 35px;
    }

    .owl-carousel .item {
        border-radius: 16px !important;
    }

    .btn-dark {
        font-size: 14px;
        line-height: 20px;
        padding: 6px 16px;
    }

    .navbar-light .navbar-nav .nav-link {
        line-height: 22px;
        font-size: 16px;
        padding: 4px 12px;
        text-align: left;
    }

    .box-testimonial .testimonial-text,
    .box-testimonial .name {
        font-size: 12px;
        line-height: 140%;
    }

    .mob-width {
        padding: 0 16px;
    }

    .features ul li,
    .audi-content ul li {
        padding-bottom: 12px;
    }

    .bottom-bg {
        width: 100%;
        margin-bottom: 20px;
    }

    .top-section {
        width: 91%;
        padding-bottom: 40px;
    }

    .top-section p {
        font-size: 17px;
        margin-bottom: 32px;

    }

    .top-section h1 {
        line-height: 140%;
        font-size: 32px;
        text-align: center;
    }

    .features {
        padding: 48px 0 12px;
    }

    .features p {
        font-size: 20px;
    }

    .features br {
        display: none;
    }

    .features h3 {
        font-size: 26px;
    }

    .nav-tabs {
        width: 100%;
        border-radius: 16px;
    }

    .nav-tabs button {
        width: 50%;
        border-radius: 8px;
    }

    .minus-margin {
        margin-top: 24px !important;
    }

    .ba9e-cap {
        display: block;
    }

    .focii-section p {
        font-size: 16px;
    }

    .navbar-collapse {
        border-radius: 8px;
        background: var(--Grey-Anti-Flash-White, #F7F7F7);
        box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.08);
        width: 150px;
        padding: 16px;
    }

    .navbar-light .navbar-toggler {
        border: 0;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .smallFocii {
        color: #CACACE;

        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .focii-section h1 {
        font-size: 32px;
        margin-top: 24px;

    }

    .focii-section .btn-dark {
        padding: 8px 24px;
    }

    .focii-section img {
        padding: 40px 0;
    }

    .audi-content h4 {
        font-size: 18px;
        margin-bottom: 24px;
    }

    .audi-content p {
        font-size: 13px;
        margin-bottom: 24px;
    }

    .desk-meeting .boxes img {
        width: 100%;
    }

    .red-section {
        padding-top: 0px;
    }

    .reasoly-box .btn-dark {
        padding: 12px 30px;
        font-size: 16px;
        width: 100%;
        margin-top: 16px;
    }

    .reasoly-box h2 {
        font-size: 16px;
        text-align: center;
        line-height: 140%;
    }

    .reasoly-box {
        padding: 20px 16px;
        display: block;
        text-align: center;
        /* margin-top: 42px; */
        border-radius: 16px;
    }

    .about-company p {
        font-size: 12px;
        letter-spacing: 0.2px;
        margin-bottom: 48px;
    }

    .footer h4,
    .footer .links li a {
        font-size: 13px;

    }

    #button {
        display: none;
    }

    .footer .about-company a {
        width: 32px;
        height: 32px;
        line-height: 32px;
    }

    .bhi-logo-socail a img {
        width: 26px;
    }

    .bhi-logo-socail {
        margin-bottom: 24px;
    }

    .bhi-logo img {
        width: 138px;
        margin-bottom: 24px;
    }

    .desk-meeting .boxes p {
        font-size: 17px;
    }

    .desk-meeting .boxes {
        padding: 16px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 16px;
    }

    .desk-meeting h3 {
        font-size: 24px;
        text-align: left;
        margin: auto;
        width: 100%;
    }

    .desk-meeting {
        padding: 48px 0 50px;
    }

    .autumn {
        padding: 40px 6px;
    }

    .autumn.electrode-f {
        padding-bottom: 16px;
    }

    .video-text {
        font-size: 16px;
        padding-top: 24px;
        padding-right: 0;
    }

    /* .video-box {
        height: 221px;
    } */

    .video-box img {
        width: 110px;
    }

    .features ul li,
    .audi-content ul li {
        display: flex;
        align-items: center;

    }

    .features ul li .bi-check img,
    .audi-content ul li .bi-check img {
        top: 0;
    }

    .audi-content {
        padding: 20px 0 45px;
    }

    .abha-section h1 {
        font-size: 32px;
    }

    .abha-section p {
        font-size: 16px;
    }

    .abha-padding {
        padding-right: 0;
        padding-bottom: 40px;
    }

    .media-logo {
        padding: 16px;
        gap: 0px;
    }

    .mobility {
        width: 91%;
        padding-top: 48px;
    }

    .mobility h2 {
        font-size: 26px;
        line-height: normal;
    }

    .mobility p {
        font-size: 15px;
        margin-bottom: 48px;
    }

    .abha-product .audi-content {
        padding-bottom: 24px;
    }

    .abha-product .audi-content h4 {
        margin-bottom: 16px;
    }

    .abha-product .autumn h2 {
        font-size: 24px;
        line-height: normal;
        text-align: left;
    }

    .autumn .cards {
        margin-top: 40px;
    }

    .autumn .cards p {
        font-size: 13px;
    }

    .autumn .cards h4 {
        font-size: 17px;
    }

    .team .cards {
        width: 48%;
        margin-top: 16px;
    }

    .team-grid {
        gap: 16px;
    }

    .team-name {
        font-size: 19px;
        padding-top: 16px;

    }

    .designation {
        font-size: 14px;
    }

    .teamMember h2 {
        font-size: 27px;
        margin-bottom: 14px;
    }

    .teamMember p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .teamMember h3 {
        margin-bottom: 8px;
        font-size: 26px;
    }

    .autumn-text h3 {
        font-size: 12px;
        margin-top: 16px;
    }

    .autumn-text {
        padding: 32px 16px;
        border-radius: 8px;
        font-size: 12px;
        line-height: normal;
        text-align: left;
        height: auto;
        border-radius: 16px;
    }

    .autumn-text br {
        display: none;
    }

    .features .learn-more {
        font-size: 14px;
    }

    .logos-media {
        padding-top: 24px;
    }

    .logo_items img {
        height: 40px;
        width: auto;
    }

    .team {
        padding-top: 48px;
    }

    .vision h3 {
        margin-bottom: 0;
    }

    .vision p {
        padding-top: 16px;
    }

    .vision img {
        border-radius: 5px;
    }

    .vision-content {
        padding: 24px 0 0;
        background: transparent;
    }

    .vision {
        margin-top: 36px;
    }

    .about-section h1 {
        font-size: 20px;
        margin-top: 16px;
        font-family: Archivo;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }



    .about-section {
        padding: 64px 16px;
        display: flex;
        align-items: center;
        /* height: 100vh; */
        background: url("../assets/team/about-us-hero-bg.png");
        background-position: unset;
        background-size: cover;
    }

    .nav-tabs .nav-link {
        font-size: 13px;
        padding: 8px 24px;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-link:focus {
        border-radius: 8px;
    }

    .headset-card {
        margin-top: 40px;
        padding: 18px 18px;
        border-radius: 16px;
    }
}
/* @media screen and (max-width:1000px) {
    .abha-product{
        padding-top: 100px;
    }
} */
@media only screen and (max-width: 420px) {
    .features p {
        font-size: 18px;
    }

    .features h3 {
        font-size: 24px;
    }

    .team .cards {
        width: 47.5%;
    }

    .team-name {
        font-size: 17px;
    }

    .designation {
        font-size: 12px;
    }
}

@media only screen and (max-width: 380px) {
    .top-section-bg {
        padding-top: 70px;
        height: 100%;
    }

    .nav-tabs .nav-link {
        font-size: 12px;
    }

    .abha-section {
        height: auto;
        display: block;
        padding-top: 39px;
    }

    .desk-meeting .boxes p {
        font-size: 17px;
    }

    .abha-section h1 {
        font-size: 28px;
    }
}

@media only screen and (min-width:980px) and (max-width: 1399px) {
    .focii-section h1 {
        font-size: 40px;
    }
}

@media only screen and (min-width:980px) and (max-width: 1399px) {
    .abha-section h1 {
        padding-top: 10px;
        font-size: 40px;
    }
}