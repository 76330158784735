.earnmore-section-bg {
    background: linear-gradient(#ff8ab90b, #b8c2ff2b);
    padding: 64px 0px;
    margin-bottom: -65px;
}

.earnmore-banner {
    background: url("../../../assets/focii-youtube/banner-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 48px;
    border-radius: 12px;
}

.earnmore-banner h1 {
    font-size: 36px;
    font-weight: 700;
    font-family: "Archivo";
    line-height: 48.4px;
    color: #ffffff;
    text-transform: uppercase;
}

.earnmore-banner h1 span {
    color: #FFDD52;
}

.earnmore-banner p {
    font-size: 16px;
    font-weight: 400;
    font-family: "Archivo";
    line-height: 19.3px;
    color: #ffffff;
    margin: 24px 0px;
}

.earnmore-banner button {
    border: 0;
    border-radius: 6px;
    padding: 8px 16px;
    background: #FFDD52;
    color: #292100;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-family: "Archivo";
}

@media screen and (max-width:768px) {

    .earnmore-banner {
        background: linear-gradient(to right, #FF8AB9, #B8C1FF);
        padding: 24px;
        border-radius: 12px;
    }

    .earnmore-banner h1 {
        font-size: 32px;
        line-height: 46.4px;

    }

}

@media screen and (max-width:520px) {
    .earnmore-section-bg {
        padding: 48px 0px;
    }

    .earnmore-banner {
        background: linear-gradient(to right, #FF8AB9, #B8C1FF);
        padding: 24px 6px !important;
        border-radius: 12px;
    }

    .earnmore-banner h1 {
        font-size: 24px;
        line-height: 36.4px;

    }

    .earnmore-banner button {
        padding: 6px 16px;
        font-size: 14px;
        width: 100%;
    }
}