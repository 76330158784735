.science-banner {
    margin-top: 64px;
    margin-bottom: 64px;
}

.science-top-header {
    height: auto;
    display: flex;
    align-items: center;
}

.science-features-container {
    margin: 64px 0px;
    padding: 64px 0px;
}

.science-features-container-bottom {
    padding: 40px 0px;
}

.the-science-text {
    font-family: Archivo;
    font-size: 88px;
    font-style: normal;
    font-weight: 700;
    margin-top: 128px;
}

.behind-brainalive-text {
    font-family: Archivo;
    font-size: 88px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}


.science-header-images {
    width: 100%;
    margin-top: 49px;
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

.science-header-images img {
    width: 33%;
    border-radius: 8px;
}


.science-features h1 {
    font-family: Archivo;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
}

.science-features p {
    text-align: justify;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-top: 16px;
}

.science-feature {
    margin-top: 58px;
}

.science-feature h1 {
    font-family: Archivo;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 48px */
    text-transform: uppercase;
}

.science-feature p {
    text-align: justify;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-top: 16px;
}


.science-btns {
    display: flex;
    gap: 16px;
}

.launch-demo-btn {

    padding: 12px 40px;
    border-radius: 8px;
    border: 1px solid #141415;
    font-family: Nunito;
    font-size: 16px;
    margin-right: 16px;
    font-weight: 700 !important;
    line-height: 120%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
}

.get-dataset-btn {
    padding: 12px 40px;
    border-radius: 8px;
    border: 1px solid #141415;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 900 !important;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
    width: fit-content;
    margin-left: 15px;
}

.launch-and-dataset-btns {
    margin-top: 24px;
}

.computer-vision-container {
    margin-top: 128px;
    margin-bottom: 64px;
}


.live-gaze-demo p {
    font-family: Nunito;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.component-max-width {
    max-width: 1366px;
}

@media screen and (max-width: 768px) {
    .science-banner {
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .the-science-text {
        font-family: Archivo;
        font-size: 54px;
        font-style: normal;
        font-weight: 700;
    }

    .behind-brainalive-text {
        font-family: Nunito;
        font-size: 54px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .science-header-images {
        width: 100%;
        margin-top: 49px;
        display: flex;
        justify-content: space-between;
        gap: 12px;
    }

    .science-header-images img {
        width: 31%;
    }


    .science-features h1 {
        font-family: Archivo;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
    }

    .science-features p {
        text-align: justify;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        margin-top: 10px;
    }

    .science-feature {
        margin-top: 20px;
    }

    .science-feature h1 {
        font-family: Archivo;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 48px */
        text-transform: uppercase;
    }

    .science-feature p {
        text-align: justify;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        margin-top: 10px;
    }

    .science-features-container {
        margin: 68px 0px;
        padding: 40px 0px;
    }

    .science-features-container-bottom {
        padding: 40px 0px;
    }

    .launch-demo-btn {

        padding: 9px 30px;
        border-radius: 8px;
        border: 1px solid #141415;
        font-family: Nunito;
        font-size: 16px;
        margin-right: 16px;
        font-weight: 700 !important;
        line-height: 120%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-top: 10px;
    }

    .get-dataset-btn {
        padding: 9px 30px;
        border-radius: 8px;
        border: 1px solid #141415;
        font-family: Nunito;
        font-size: 16px;
        margin-right: 16px;
        font-weight: 900 !important;
        line-height: 120%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }

    .launch-and-dataset-btns {
        margin-top: 12px;
    }
}

@media screen and (max-width:520px) {


    .the-science-text {
        font-family: Archivo;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        margin-top: 128px;
    }

    .behind-brainalive-text {
        font-family: Nunito;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .get-dataset-btn {
        padding: 12px 40px;
        border-radius: 8px;
        border: 1px solid #141415;
        font-family: Nunito;
        font-size: 16px;
        font-weight: 900 !important;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        text-decoration: none;
        width: fit-content;
        margin-left: -5px;
    }
    .launch-and-dataset-btns {
        margin-top: 0px;
    }

    .science-header-images {
        width: 100%;
        margin-top: 49px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
    }

    .science-header-images img {
        width: 100%;
    }


    .science-features h1 {
        font-family: Archivo;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
    }

    .science-features p {
        text-align: justify;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-top: 10px;
    }

    .science-feature {
        margin-top: 20px;
    }

    .science-feature h1 {
        font-family: Archivo;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 48px */
        text-transform: uppercase;
    }

    .science-feature p {
        text-align: justify;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-top: 10px;
    }

    .science-features-container {
        margin: 58px 0px;
        padding: 30px 0px;
    }

    .launch-demo-btn {
        width: 100% !important;
        padding: 9px 30px;
        border-radius: 8px;
        border: 1px solid #141415;
        font-family: Nunito;
        font-size: 16px;
        margin-right: 16px;
        font-weight: 700 !important;
        line-height: 120%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-top: 10px;
        text-decoration: none;

    }

    .get-dataset-btn {
        width: 100% !important;
        padding: 9px 30px;
        border-radius: 8px;
        border: 1px solid #141415;
        font-family: Nunito;
        font-size: 16px;
        margin-right: 16px;
        font-weight: 900 !important;
        line-height: 120%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-top: 20px;
        text-decoration: none;
        width: 90% !important;
        position: relative;
        left: 5%;
        text-align: center;
    }

    .launch-and-dataset-btns a {
        display: inline-block;
        text-align: center;
        color: #141415;
    }
    .launch-and-dataset-btns {
        margin-top: 0px;
    }
}

@media screen and (max-width:390px) {
    .science-banner {
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .the-science-text {
        font-family: Archivo;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
    }

    .behind-brainalive-text {
        font-family: Nunito;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

}

@media screen and (min-width: 1024px) {
    .cv-nlp {
        padding-right: 30px;
    }

    .nlp-cv {
        padding-left: 30px;
    }
}