.dynamic-tag-related-blog {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
}

.search-for-heading {
    margin-top: 3rem;
    margin-bottom: -1rem;
}

.search-result {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

}

#tag-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
}

.show-in-mobile {
    display: none;
}

@media only screen and (max-width: 520px) {
    .tag-blog-container {
        margin-left: 0;
        margin-right: 0;
    }

    .search-for-heading {
        margin-top: 5rem;
        margin-bottom: -1rem;
        margin-left: 1rem;
    }
    .show-in-mobile {
        display: block;
  
    }
    .show-in-mobile img{
 
        border-radius: 7px;
        margin-bottom: 12px;
    }
}