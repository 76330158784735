.key-features-title {
    text-align: center;
    font-family: "Archivo";
    font-size: 48px;
    font-weight: 700;
    line-height: 52.22px;
    color: #303031;
    margin: 48px 0px;
}

.key-features-card {
    background: #F7F7F7;
    border-radius: 24px;
    padding: 18px;
    width: 28%;
    min-height: 370px;
    /* text-align:  justify; */
}

.key-features-card h5 {
    font-size: 32px;
    font-family: "Nunito";
    line-height: 34.82px;
    margin-top: 24px;
}

.key-features-card p {
    font-size: 20px;
    font-family: "Nunito";
    line-height: 32px;
    font-weight: 400;
}
@media screen and (max-width: 1200px) {
    .key-features-card {
        min-height: 402px;
        /* text-align:  justify; */
    }
}

@media screen and (max-width: 991px) {
    .key-features-card {
        border-radius: 16px;
        padding: 20px;
        width: 45%;
        min-height: 345px;
    }

    .key-features-title {
        font-size: 36px;
        font-weight: 700;
        line-height: 52.22px;
        margin: 36px 0px;
    }
    
    .key-features-card h5 {
        font-size: 26px;
        line-height: 34.82px;
        margin-top: 24px;
    }
    
    .key-features-card p {
        font-size: 18px;
    }
}
@media screen and (max-width: 770px) {
    .key-features-card {
       
        min-height: 437px;
    }
}

@media screen and (max-width: 520px) {
    .key-features-card {
        border-radius: 16px;
        padding: 20px;
        width: 94%;
        min-height: 239px;
    }

    .key-features-title {
        font-size: 30px;
        font-weight: 700;
        line-height: 36.22px;
        margin: 36px 0px;
    }
    
    .key-features-card h5 {
        font-size: 26px;
        font-family: "Nunito";
        line-height: 34.82px;
        margin-top: 24px;
    }
    
    .key-features-card p {
        font-size: 18px;
        font-family: "Nunito";
        line-height: 28px;
        font-weight: 400;
    }
    .key-features-card img{
       width: 60px;
    }
}