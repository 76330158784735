.activeTab {
    color: blue !important;
    font-size: 28px;
}

.dropdown-bg {
    border-radius: 8px;
    background: #141415;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    /* width: 199px !important; */
    color: #686869 !important;
    padding: 12px !important;
    text-align: left !important;
    height: auto;
}

.first-dropdown {
    border: 0 !important;
    background: 0;
    color: #8A8A93;
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    width: 100px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.nav-item {
    display: flex;
    align-items: center;

}

.first-dropdown:focus {
    color: #000;

}


.dropdown-toggle::after {
    display: inline-block;
    margin-left: .5em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-item.active {
    background: none !important;
    color: #000;
}

.dropdown-item {
    color: #8A8A93 !important;
    text-align: left !important;
    padding: 0;
}

.dropdown-item small {
    color: #686869;
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    position: relative;
    bottom: 5px;
    padding: 0;
    letter-spacing: .5px;
}

.dropdown-item:focus {
    background: none;
}

.dropdown-item:hover {
    background: none;
}

.second-dropdown {
    padding: 12px;
    background: #282829;
    border-radius: 4px;
}

.second-dropdown-items li {
    margin-top: 10px;
}

.nested-link {
    text-decoration: none;
    color: #686869;
    text-align: center;
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.nested-link small {
    letter-spacing: .0px;
}

.nested-link:hover {

    color: #686869;

}

.active-link {
    color: #fff !important;
}

.active-link small {
    color: #fff !important;
}

.second-arrow::after {
    display: inline-block;
    margin-left: .5em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: relative;
    left: 85%;
    top: 60%;
    cursor: pointer;
    position: absolute;

}

.third-arrow::after {
    display: inline-block;
    margin-left: .5em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: relative;
    left: 85%;
    rotate: 180deg;
    cursor: pointer;
    position: absolute;
}

.products-dropdown {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background: var(--Grey-Anti-Flash-White, #F7F7F7);
    box-shadow: 4px 16px 24px 0px rgba(0, 5, 41, 0.08);
    padding: 32px;
}

.products-dropdown-sections {
    display: flex;
    justify-content: center;
    gap: 48px;
}

.brain-computer-interface-sections {
    display: flex;
    justify-content: center;
    gap: 24px;
}

.division-heading {
    color: #000529;
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}


.product-division p {
    line-height: normal;
    margin-bottom: 0 !important;
}

.brain-computer-interface p {
    margin-bottom: 0 !important;

}

.products-item {
    cursor: pointer;
    margin-top: 16px;
    padding: 4px 8px;
}

.products-item h6 {
    color: #4C4C4D;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0;

}

.products-item p {
    color: #4C4C4D;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.products-item:hover {
    border-radius: 4px;
    background: #EBEDFF;
    /* padding: 4px 8px; */
}

.active-product {
    padding: 4px 8px;
    border-radius: 4px;
    background: #EBEDFF;
}

@media screen and (max-width:520px) {
    .first-dropdown {
        margin: 10px 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0px 12px;

    }

    .first-dropdown:focus {
        color: #4C4C4D;

    }

    .navbar-nav .dropdown-menu {
        position: absolute !important;
        top: 70%;
        left: 20%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .navbar-light .navbar-nav .nav-link.active:before {
        content: "";
        position: absolute;
        width: 50%;
        height: 2px;
        bottom: 5px;
        left: 0;
        background-color: #fff;
        visibility: hidden;
        width: 0px;
        transition: all 0.3s ease-in-out 0s;
    }

    .navbar-light .navbar-nav .nav-link.active:before {
        visibility: visible;
        width: 10%;
        left: 6%;
    }

    .products-dropdown {

        position: relative;
        top: 10px;
        left: 0;
        right: 0;
        width: 100%;
        display: flex;
        justify-content: start;
        background: none;
        box-shadow: none;
        padding: 11px;
        height: 300px;
    }

    .nav-item {
        border-bottom: 1px solid #D8D8D9;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .nav-book-demo-btn {
        width: 100%;
        margin-top: 10px;
    }

    .products-dropdown-sections {
        display: unset;
    }

    .brain-computer-interface-sections {
        display: unset;

    }

    .brain-computer-interface {
        margin-top: 15px;
    }

    .products-item {
        padding: 0px 10px;
    }

    .division-heading {
        color: #4C4C4D;
        font-family: Archivo;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
    }
}

