.download-page-container{
    padding-top: 74px;
}

.download-button-with-logo{
    display: flex;
    justify-content:space-between;
    align-items:center;
    padding: 15px 128px 15px 128px;
}

.download-logo{
    color:#313135;
    font-family: Archivo;
font-size: 40px;
font-weight: 600;
line-height: 44px;
letter-spacing: 0em;
text-align: left;

}

.how-to-install{
    font-family: Archivo;
font-size: 16px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
text-decoration: underline;
cursor: pointer;

}

.install-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 30%;
}

.download-button{
    padding: 5px 12px;
    border-radius: 6px;
    background-color:  #000000;
    color:  #FFFFFF;
    font-family: Archivo;
}

.overview{
    font-family: Archivo;
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px 128px;

}

.overviewText{
    font-family: Archivo;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 5px 128px;
    
}
.mvpOverview{
    padding: 10px 120px;
    font-family: Archivo;
    text-align: justify;
}

@media screen and (max-width:1300px) {
    .download-button{
        padding: 5px 10px;
        border-radius: 6px;
        background-color:  #000000;
        color:  #FFFFFF;
        font-family: Archivo;
    }
    .install-buttons{
        
        width: 40%;
    }
}
@media screen and (max-width:1050px) {
   
    .install-buttons{
        
        width: 50%;
    }
}
@media screen and (max-width:900px) {
   
    .install-buttons{
        
        width: 60%;
    }
    .mvpOverview{
        padding: 10px 70px;
    }
    .overviewText{
        padding: 10px 70px;
    }
}
@media screen and (max-width:810px) {
   
    .install-buttons{
        justify-content: space-between;
        width: 60%;
    }
}

@media screen and (max-width:790px) {
   
    .install-buttons{
        justify-content: space-evenly;
        width: 70%;
    }

    .how-to-install{
        font-family: Archivo;
    font-size: 16px;

    
    }
    .mvpOverview{
        padding: 10px 50px;
    }
    .overviewText{
        padding: 10px 50px;
    }
}

@media screen and (max-width:760px){
    .how-to-install{
        display: none;
    }
    .install-buttons{
        width: 35%;
    }
    .download-button{
        font-size: small;
    }
    .download-button-with-logo{
        padding: 15px 88px 15px 88px;
    }
    .overview{
        padding: 10px 85px;
    }
    .overviewText{
        padding: 5px 85px;
        text-align: justify;
    }
}

@media screen and (max-width:600px) {
    .install-buttons{
        width: 45%;
    }
    
    .download-button-with-logo{
        padding: 15px 45px 15px 45px;
    }
    .overview{
        padding: 10px 45px;
    }
    .overviewText{
        padding: 5px 45px;
        text-align: justify;
    }
}

@media screen and (max-width:490px){
    .install-buttons{
        width: 55%;
    }
    
    .download-button-with-logo{
        padding: 15px 25px 15px 25px;
    }
    .overview{
        padding: 10px 25px;
    }
    .overviewText{
        padding: 5px 25px;
        text-align: justify;
    }
}

@media screen and (max-width:380px){
    .download-logo{
        font-size: 30px;
    }

    .download-button{
        padding: 5px;
    }
}

@media screen and (max-width:280px){
    .download-logo{
        font-size: 25px;
    }
    .download-button{
        padding: 2px;
    }
}