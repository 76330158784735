.loading-image-container {
    left: 0;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    background: linear-gradient(to left, #21212199, #29292999, #1c1c1c99)
}