.online-teaching-bg {
    height: 536px;
    background-image: linear-gradient(125.69deg, rgba(41, 41, 41, 0.74) 0%, rgba(25, 25, 25, 0.74) 100%), url('../../../assets/blogs/online-teaching.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(12px);
    margin: -20px;
    position: relative;
}

@media only screen and (max-width: 520px) {
    .online-teaching-bg {
        height: 400px;
        filter: blur(5px);
        margin: -0px;
    }

    .online-teaching-heading-content {
        top: 2%;
    }
}