.blogs-div {
    border-bottom: 1px solid #EAEAEB;
    padding-bottom: 1rem;
}

.blog-card-img {
    height: 160px;
    /* width: 100%; */
    width: 100%;
    max-width: 252.79px;
    border-radius: 5px;
    transition: 1.5s all ease-in-out;
  
}

.blog-card-img-overflow {
    border-radius: 5px;
    overflow: hidden;
    /* position: relative;
    right: 12px; */
}

.blog-card-img:hover {
    transform: scale(1.5);
}

.read-more-overflow {
    height: 70px;
    overflow: hidden;
}

.blog-title-text {
    font-weight: 700;
    font-family: Nunito;
    font-size: 18px !important;
    line-height: 22px
}

.read-more-text {
    Size: 16px;
    Line-height: 22px;
    font-family: Nunito;
}

.blog-read-more {
    font-size: 12px;
    line-height: 16px;
    color: #0C8AE6;
    font-family: Nunito;
    font-weight: 700;
}



.blog-by {
    color: #F6F6F6;
    font-family: Nunito;
    font-weight: 300;
    line-height: 1.618;
    font-size: 14px;
}

.blog-bottom-dot {
    font-size: 30px;
    position: relative;
    bottom: 22px;
    padding-left: -0px !important;
    opacity: .9;
    color: gray;
    margin-right: -1rem;
    margin-left: -10px;
}

.posted-by {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
}

.blog-buttons {
    margin-top: -.8rem;
    margin-left: 2rem;

}

.blogs-margin-top {
    margin-top: 2rem;
}

.share-div {
    margin-top: -1rem;
    cursor: pointer;
}

.date-div {
    display: flex;
}

.date-dot {
    font-size: 30px;
    position: relative;
    bottom: 22px;
    padding-left: 0px;
    padding-right: 10px;
    opacity: .9;
}

.blog-searching {
    margin-bottom: 64px;
}

.blog-searching input {
    width: 97%;
    height: 48px;
    padding: 8px;
    border: 0;
    border-bottom: 1px solid var(--grey-06, #4E4E55);
    background: none;
    color: #fff;
}

.blog-searching input:focus {
    outline: none;
    color: #fff;
}

.blog-searching button {
    width: 100%;

    height: 48px;
    border: 0;
    border-radius: 4px;
    background: #1974CE;
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #fff;

}

.date-and-tag-flex {
    display: flex;
}

.tag-btn-1 {
    background: #D2CDF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}


.tag-btn-2 {
    background: #CDDBF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-3 {
    background: #F4E6CD;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-4 {
    background: #D2CDF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-5 {
    background: #CDDBF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-6 {
    background: #F4E6CD;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-7 {
    background: #D2CDF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-8 {
    background: #CDDBF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-9 {
    background: #F4E6CD;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-10 {
    background: #D2CDF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-11 {
    background: #CDDBF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-12 {
    background: #F4E6CD;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-13 {
    background: #D2CDF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-14 {
    background: #CDDBF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-15 {
    background: #F4E6CD;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-16 {
    background: #D2CDF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-17 {
    background: #CDDBF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-18 {
    background: #F4E6CD;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-19 {
    background: #D2CDF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-20 {
    background: #CDDBF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-21 {
    background: #F4E6CD;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-22 {
    background: #D2CDF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-23 {
    background: #CDDBF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-24 {
    background: #F4E6CD;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-25 {
    background: #D2CDF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-26 {
    background: #CDDBF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-27 {
    background: #F4E6CD;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-28 {
    background: #D2CDF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-29 {
    background: #CDDBF4;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

.tag-btn-30 {
    background: #F4E6CD;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
    padding: 5px 8px;
    border-radius: 4px;
    border: 0;
    margin-right: 5px;
}

@media only screen and (min-width: 1200px) {
    .main-blog-card-text {
        margin-left: -3rem;
    }
}

@media only screen and (min-width: 520px) {
    /* .blog-card-text {
        padding-left: 1.5rem;
        padding-right: 1rem;

    } */

    .blog-title-text {

        font-size: 26px;

    }
}

@media only screen and (max-width: 519px) {
    .blog-searching {
        margin: 0;
    }

    .date-and-tag-flex {
        display: unset;
    }

    .blogs-div {
        margin: 1rem;
    }

    .blog-buttons {
        margin-top: -.8rem;
        margin-left: 0rem;

    }

    .blog-card-text {
        width: 110%;


    }

    .blog-card-img {
        height: 185px;
        width: 370px;
        border-radius: 20px;
        margin-bottom: 1rem;
    }

    .blogs-margin-top {
        margin-top: 2rem;
    }

    .date-dot {
        display: block;
    }

    .blog-bottom-dot {
        display: none;
    }

    .date-div {
        display: flex;
    }

    .read-time {
        display: block;
    }

    .blog-searching {
        margin-bottom: 64px;
        padding: 20px;
    }

    .blog-searching input {
        width: 97%;
        height: 48px;
        padding: 8px;
        border: 0;
        border-bottom: 1px solid var(--grey-06, #4E4E55);
        background: none;
        color: #fff;
    }

    .blog-searching input:focus {
        outline: none;
        color: #fff;
    }

    .blog-searching button {
        width: 97%;

        height: 48px;
        border: 0;
        border-radius: 4px;
        background: #1974CE;
        font-family: Archivo;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #fff;
        margin-top: 24px;
    }
}

@media only screen and (min-width: 390px) and (max-width: 400px) {
    .blog-card-text {
        width: 100%;
    }

    .blog-card-img {
        height: 185px;
        width: 330px;
        border-radius: 25px;
    }

    .blogs-margin-top {
        margin-top: 0rem;
    }
}

@media only screen and (max-width: 375px) {
    .blog-card-text {

        width: 100%;
    }

    .blog-card-img {
        height: 185px;
        width: 320px;
        border-radius: 20px;
    }

    .blog-title-text {

        font-size: 25px;

    }
}

@media only screen and (max-width: 320px) {
    .blog-card-text {

        width: 100%;
    }

    .blog-card-img {
        height: 185px;
        width: auto;
        border-radius: 25px;
    }
}

@media only screen and (min-width: 900px) {

    .date {
        width: 85px;
    }
}

@media screen and (max-width: 768px) and (min-width: 521px) {
    .blog-card-img {
        height: 160px;
        width: 100%px;
        border-radius: 5px;
        transition: 1.5s all ease-in-out;
        margin-bottom: 15px;
    }


}

.blog-bg {
    background: linear-gradient(125.69deg, #FFFFFF 0%, #CCCCCC 100%);
    display: flex;
    justify-content: center;
}

.all-blogs-card-max-width {
    max-width: 1400px !important;
    padding: 64px;
}

.home-blog-container {

    margin-left: 2rem;
    margin-right: -2rem;
    width: 90%;

}

.blog-container {
    margin-left: 2.5rem;
    /* width: 90%; */

}

@media only screen and (max-width: 520px) {
    .all-blogs-card-max-width {
        max-width: 1400px !important;
        padding: 0px;
        margin-top: 2rem;
    }

    .blog-container {
        margin-left: 2rem;
        margin-right: 0;
        width: 95%;
        padding: .5rem;
    }

    .home-blog-container {

        margin-left: -.1rem;
        margin-right: 0;
        width: 95%;
        padding: .5rem;

    }

    .blog-card-img-overflow {
        display: none !important;
    }

    .mobile-blogs-img-padding {
        padding: 0 !important;
    }

    .mobile-blogs-img img {
        width: 100% !important;
        padding: 0 !important;
        border-radius: 4px;
        margin-bottom: 10px;
    }
}

@media screen and (min-width:521px) {
    .mobile-blogs-img {
        display: none;
    }
}