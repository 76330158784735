.about-hero-text {
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.64);
    backdrop-filter: blur(4px);
    padding: 64px;
}

@media screen and (max-width: 600px) {
    .about-hero-text {
        border-radius: 16px;
        background: rgba(0, 0, 0, 0.64);
        backdrop-filter: blur(4px);
        padding: 24px 16px;
    }
}